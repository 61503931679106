import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";
import { UserSearchHistory } from "../../types/forwarding/adminBidUser";

import {
  QueryResponseHandlerCustomMessage,
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "../../components/QueryResponseHandler";

import {
  CREATE_USER_OTP_REQ,
  CREATE_USER_OTP_RES,
  DELETE_USER_REQ,
  DELETE_USER_RES,
  EDIT_USER_DETAIl_REQ,
  EDIT_USER_DETAIL_RES,
  GET_ADMIN_BID_USER_DETAIL_REQ,
  GET_ADMIN_BID_USER_DETAIl_RES,
  GET_ADMIN_NEW_BID_USER_DETAIl_RES,
  GET_ADMIN_NEW_BID_USER_REQ,
  GET_ADMIN_NEW_BID_USER_RES,
  GET_ADMIN_USER_GUIDE_LIST_REQ,
  GET_ADMIN_USER_GUIDE_LIST_RES,
  GET_FULLFILLMENT_MANAGERS_REQ,
  GET_FULLFILLMENT_MANAGERS_RES,
  REGISTER_BID_USER_REQ,
  REGISTER_BID_USER_RES,
  UPDATE_ADMIN_BID_USER_COMMENT_REQ,
  UPDATE_ADMIN_BID_USER_COMMENT_REQ_PATH_PARAMS,
  UPDATE_ADMIN_BID_USER_COMMENT_RES,
  UPDATE_BOFUL_MANAGER_REQ,
  UPDATE_BOFUL_MANAGER_RES,
  UPDATE_SALES_MANAGER_REQ,
  UPDATE_SALES_MANAGER_RES,
  UPDATE_USER_DETAIL_REQ,
  UPDATE_USER_DETAIL_RES,
} from "../../api-interfaces/shipda-api/adminBidUser";

export const ADMIN_BID_USER_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_BID_USER_QUERY_KEY_GEN" }] as const,

  getAdminBidUserDetail: (params: GET_ADMIN_BID_USER_DETAIL_REQ) =>
    [
      {
        ...ADMIN_BID_USER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getDetail",
      },
    ] as const,

  getFullfillmentManagers: (params: GET_FULLFILLMENT_MANAGERS_REQ) =>
    [
      {
        ...ADMIN_BID_USER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getFullfillmentManagers",
      },
    ] as const,

  getUserSearchHistory: () =>
    [
      {
        ...ADMIN_BID_USER_QUERY_KEY_GEN.all()[0],
        entity: "getUserSearchHistory",
      },
    ] as const,

  getAdminBidPortList: () =>
    [
      {
        ...ADMIN_BID_USER_QUERY_KEY_GEN.all()[0],
        entity: "getPortList",
      },
    ] as const,

  getUserGuideList: (params: GET_ADMIN_USER_GUIDE_LIST_REQ) =>
    [
      {
        ...ADMIN_BID_USER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getUserGuideList",
      },
    ] as const,

  getAdminNewBidUserDetail: (id: number) =>
    [
      {
        ...ADMIN_BID_USER_QUERY_KEY_GEN.all()[0],
        id,
        entity: "getAdminNewBidUserDetail",
      },
    ] as const,

  getAdminNewBidUserList: (params: GET_ADMIN_NEW_BID_USER_REQ) =>
    [
      {
        ...ADMIN_BID_USER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminNewBidUserList",
      },
    ] as const,
};

function useGetAdminBidUserDetail(params: GET_ADMIN_BID_USER_DETAIL_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_USER_QUERY_KEY_GEN.getAdminBidUserDetail>,
    GET_ADMIN_BID_USER_DETAIl_RES
  >({
    queryKey: ADMIN_BID_USER_QUERY_KEY_GEN.getAdminBidUserDetail(params),
    requestOptions: {
      method: "get",
      path: `/users/${params.userId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "사용자관리 상세 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetFullfillmentManagers(params: GET_FULLFILLMENT_MANAGERS_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_USER_QUERY_KEY_GEN.getFullfillmentManagers>,
    GET_FULLFILLMENT_MANAGERS_RES
  >({
    queryKey: ADMIN_BID_USER_QUERY_KEY_GEN.getFullfillmentManagers(params),
    requestOptions: {
      method: "get",
      path: `/fulfillment/managers`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },

    keepPreviousData: true,
    failureModalInfo: {
      customizeMessage: () => ({
        title: "풀필먼트 매니저 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetUserSearchHistory(userId: number) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_USER_QUERY_KEY_GEN.getUserSearchHistory>,
    UserSearchHistory[]
  >({
    queryKey: ADMIN_BID_USER_QUERY_KEY_GEN.getUserSearchHistory(),
    requestOptions: {
      method: "get",
      path: `/users/${userId}/searchHistory`,
      apiType: "ShipdaAdminDefaultNew",
    },

    keepPreviousData: true,
    failureModalInfo: {
      customizeMessage: () => ({
        title: "사용자 검색기록 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useDeleteUser(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    userId: number;
  } | void
) {
  const { successModalInfo, userId } = props || {};

  const mutation = useAppMutation<DELETE_USER_REQ, DELETE_USER_RES>({
    requestOptions: {
      method: "delete",
      path: `/users/${userId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useUpdateBofulManager(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
  } | void
) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<
    UPDATE_BOFUL_MANAGER_REQ,
    UPDATE_BOFUL_MANAGER_RES
  >({
    requestOptions: {
      method: "patch",
      path: "/users/assign/fullfillment",
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useUpdateSalesManager(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    userId: number;
  } | void
) {
  const { successModalInfo, userId } = props || {};

  const mutation = useAppMutation<
    UPDATE_SALES_MANAGER_REQ,
    UPDATE_SALES_MANAGER_RES
  >({
    requestOptions: {
      method: "put",
      path: `/management/user/${userId}/assignManager`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useCreateUserOtp() {
  const mutation = useAppMutation<CREATE_USER_OTP_REQ, CREATE_USER_OTP_RES>({
    requestOptions: {
      method: "post",
      path: "/users/otp",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useEditUserDetail(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
  } | void
) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<EDIT_USER_DETAIl_REQ, EDIT_USER_DETAIL_RES>({
    requestOptions: {
      method: "put",
      path: `/users`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E404: {
              messageType: "titleOnly" as const,
              title: "수정된 정보가 없습니다.",
            },

            E357: {
              messageType: "titleOnly" as const,
              title: "이메일이 중복됐습니다.",
            },
          }[errorCode ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({ errorCode: failureInfo?.errorCode });
      },
    },
  });

  return { ...mutation };
}

function useUpdateComment() {
  const mutation = useAppMutation<
    UPDATE_ADMIN_BID_USER_COMMENT_REQ,
    UPDATE_ADMIN_BID_USER_COMMENT_RES,
    UPDATE_ADMIN_BID_USER_COMMENT_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ userId }) => `/users/${userId}/comments`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useRegisterUser(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
  } | void
) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<REGISTER_BID_USER_REQ, REGISTER_BID_USER_RES>(
    {
      requestOptions: {
        method: "post",
        path: `/auth/register`,
        apiType: "ShipdaAdminDefaultNew",
      },
      successModalInfo,

      failureModalInfo: {
        customizeMessage: (failureInfo) => {
          if (
            failureInfo?.errorCode === "E210" &&
            failureInfo.error === "is not phone number"
          ) {
            return {
              messageType: "titleAndBody",
              title: "전화번호 형식을 확인해주세요.",
              body: "숫자만 입력 가능합니다.",
            };
          }

          if (
            failureInfo?.errorCode === "E212" &&
            failureInfo.error === "phone already in use"
          ) {
            return {
              title: "이미 가입된 전화번호입니다.",
            };
          }

          return {
            title: "에러가 발생했습니다.",
          };
        },
      },
    }
  );

  return { ...mutation };
}

function useGetUserGuideList(params: GET_ADMIN_USER_GUIDE_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_USER_QUERY_KEY_GEN.getUserGuideList>,
    GET_ADMIN_USER_GUIDE_LIST_RES
  >({
    queryKey: ADMIN_BID_USER_QUERY_KEY_GEN.getUserGuideList(params),
    requestOptions: {
      method: "get",
      path: `/customerCare`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },

    keepPreviousData: true,
    failureModalInfo: {
      customizeMessage: () => ({
        title: "가이드 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

// 기존에 사용자 상세를 불러오는 /users/{userId}와 구분을 위해 new를 붙임 작업 완료 후 이름 수정
function useGetAdminNewBidUserDetail(id: number) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_USER_QUERY_KEY_GEN.getAdminNewBidUserDetail>,
    GET_ADMIN_NEW_BID_USER_DETAIl_RES
  >({
    queryKey: ADMIN_BID_USER_QUERY_KEY_GEN.getAdminNewBidUserDetail(id),
    requestOptions: {
      method: "get",
      path: `/users/team/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "사용자관리 상세 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useUpdateUserDetail({
  successModalInfo,
  failureModalInfo,
}: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
}) {
  const mutation = useAppMutation<
    UPDATE_USER_DETAIL_REQ,
    UPDATE_USER_DETAIL_RES
  >({
    requestOptions: {
      method: "put",
      path: "/users",
      apiType: "ShipdaAdminDefaultNew",
    },

    successModalInfo,

    failureModalInfo,
  });

  return { ...mutation };
}

function useGetAdminNewBidUserList({
  params,
  onSuccess,
  enabled,
}: {
  params: GET_ADMIN_NEW_BID_USER_REQ;
  onSuccess?: (res: GET_ADMIN_NEW_BID_USER_RES) => void;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_USER_QUERY_KEY_GEN.getAdminNewBidUserList>,
    GET_ADMIN_NEW_BID_USER_RES
  >({
    queryKey: ADMIN_BID_USER_QUERY_KEY_GEN.getAdminNewBidUserList(params),
    requestOptions: {
      method: "get",
      path: "/users",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },

    keepPreviousData: true,
    enabled,
    onSuccess,
  });

  return { ...queryResult };
}

const ADMIN_BID_USER_QUERY = {
  useGetAdminBidUserDetail,
  useGetFullfillmentManagers,
  useGetUserSearchHistory,
  useDeleteUser,
  useUpdateBofulManager,
  useUpdateSalesManager,
  useCreateUserOtp,
  useEditUserDetail,
  useUpdateComment,
  useRegisterUser,
  useGetUserGuideList,
  useGetAdminNewBidUserDetail,
  useUpdateUserDetail,
  useGetAdminNewBidUserList,
};

export default ADMIN_BID_USER_QUERY;
