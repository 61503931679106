import { useMemo } from "react";

import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";
import { ResponseFailureInfo } from "../../types/common/common";
import { SettlementSalesChitExcelListData } from "../../types/forwarding/adminSettlement";
import { toFormattedDate } from "../../utils/common/date";
import { downloadFile } from "../../utils/common/file";
import { changePurchaseDomainToKr } from "../../utils/forwarding/trello";

import {
  CANCEL_WITHDRAW_REQ,
  CANCEL_WITHDRAW_RES,
  CHECK_INVOICE_CANCEL_REQ,
  CHECK_REMITTANCE_CERTIFICATE_REQ,
  CHECK_REMITTANCE_CERTIFICATE_RES,
  DELETE_INVOICE_REQ,
  DELETE_INVOICE_RES,
  DELETE_WITHDRAW_REQ,
  DELETE_WITHDRAW_RES,
  EDIT_INVOICE_ISSUE_DATE_REQ,
  EDIT_INVOICE_ISSUE_DATE_RES,
  EDIT_WITHDRAWAL_DATE_REQ,
  EDIT_WITHDRAWAL_DATE_RES,
  FORCE_CANCEL_TAX_INVOICE_REQ,
  FORCE_CANCEL_TAX_INVOICE_RES,
  GET_ADMIN_SETTLEMENT_DEPOSIT_COMPANIES_REQ,
  GET_ADMIN_SETTLEMENT_DEPOSIT_COMPANIES_RES,
  GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_EXCEL_REQ,
  GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_EXCEL_RES,
  GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_REQ,
  GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_RES,
  GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_V2_REQ,
  GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_V2_RES,
  GET_ADMIN_SETTLEMENT_LIST_REQ,
  GET_ADMIN_SETTLEMENT_LIST_RES,
  GET_ADMIN_SETTLEMENT_PURCHASE_EXCEL_LIST_REQ,
  GET_ADMIN_SETTLEMENT_PURCHASE_EXCEL_LIST_RES,
  GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ,
  GET_ADMIN_SETTLEMENT_PURCHASE_LIST_RES,
  GET_ADMIN_SETTLEMENT_SALES_CHIT_EXCEL_LIST_REQ,
  GET_ADMIN_SETTLEMENT_SALES_EXCEL_LIST_REQ,
  GET_ADMIN_SETTLEMENT_SALES_EXCEL_LIST_RES,
  GET_ADMIN_SETTLEMENT_SALES_LIST_REQ,
  GET_ADMIN_SETTLEMENT_SALES_LIST_RES,
  GET_ADMIN_SETTLEMENT_WITHDRAWAL_EXCEL_LIST_REQ,
  GET_ADMIN_SETTLEMENT_WITHDRAWAL_EXCEL_LIST_RES,
  GET_ADMIN_SETTLEMENT_WITHDRAWAL_LIST_REQ,
  GET_ADMIN_SETTLEMENT_WITHDRAWAL_LIST_RES,
  GET_ADMIN_SETTLEMENT_WITHDRAWAL_PARTNERS_REQ,
  GET_ADMIN_SETTLEMENT_WITHDRAWAL_PARTNERS_RES,
  ISSUE_AUTO_INVOICE_REQ,
  ISSUE_AUTO_INVOICE_RES,
  ISSUE_MANUAL_INVOICE_REQ,
  ISSUE_MANUAL_INVOICE_REQ_RES,
  MATCH_BID_ID_TO_DEPOSIT_REQ,
  MATCH_BID_ID_TO_DEPOSIT_RES,
  MATCH_BID_ID_TO_WITHDRAWAL_REQ,
  MATCH_BID_ID_TO_WITHDRAWAL_RES,
  REGISTER_FINANCIAL_MEMO_REQ,
  REGISTER_FINANCIAL_MEMO_RES,
} from "../../api-interfaces/shipda-api/adminSettlement";

export const ADMIN_SETTLEMENT_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_SETTLEMENT_QUERY" }] as const,

  getAdminSettlementSalesList: (params: GET_ADMIN_SETTLEMENT_SALES_LIST_REQ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementSalesList",
      },
    ] as const,

  getAdminSettlementSalesExcelList: (
    params: GET_ADMIN_SETTLEMENT_SALES_EXCEL_LIST_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementSalesExcelList",
      },
    ] as const,

  getAdminSettlementList: (params: GET_ADMIN_SETTLEMENT_LIST_REQ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementList",
      },
    ] as const,

  getAdminSettlementDepositList: (
    params: GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementDepositList",
      },
    ] as const,

  getAdminSettlementDepositCompanies: (
    params: GET_ADMIN_SETTLEMENT_DEPOSIT_COMPANIES_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementDepositCompanies",
      },
    ] as const,

  getAdminSettlementDepositExcelList: (
    params: GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_EXCEL_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementDepositExcelList",
      },
    ] as const,

  getAdminSettlementWithdrawalList: (
    params: GET_ADMIN_SETTLEMENT_WITHDRAWAL_LIST_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementWithdrawalList",
      },
    ] as const,

  getAdminSettlementWithdrawalPartners: (
    params: GET_ADMIN_SETTLEMENT_WITHDRAWAL_PARTNERS_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementPartnersCompanies",
      },
    ] as const,

  getAdminSettlementWithdrawalExcelList: (
    params: GET_ADMIN_SETTLEMENT_WITHDRAWAL_EXCEL_LIST_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementWithdrawalExcelList",
      },
    ] as const,

  getAdminSettlementSalesChitExcelList: (
    params: GET_ADMIN_SETTLEMENT_SALES_EXCEL_LIST_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminSettlementSalesExcelChitList",
      },
    ] as const,

  getAdminSettlementPurchaseList: (
    params: GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminSettlementPurchaseList",
      },
    ] as const,

  getAdminSettlementPurchaseExcelList: (
    params: GET_ADMIN_SETTLEMENT_PURCHASE_EXCEL_LIST_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminSettlementPurchaseExcelList",
      },
    ] as const,

  getAdminSettlementDepositV2List: (
    params: GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_V2_REQ
  ) =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminSettlementDepositV2List",
      },
    ] as const,

  getAdminSettlementDepositV2ExcelList: () =>
    [
      {
        ...ADMIN_SETTLEMENT_QUERY_KEY_GEN.all()[0],
        entity: "adminSettlementDepositV2ExcelList",
      },
    ] as const,
};

function useGetAdminSettlementSalesList({
  params,
  onSuccess,
}: {
  params: GET_ADMIN_SETTLEMENT_SALES_LIST_REQ;
  onSuccess?: (data: GET_ADMIN_SETTLEMENT_SALES_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementSalesList
    >,
    GET_ADMIN_SETTLEMENT_SALES_LIST_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementSalesList(params),
    requestOptions: {
      method: "get",
      path: "/settlement/sales",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    keepPreviousData: true,
    onSuccess,
  });

  return { ...queryResult };
}

function useGetAdminSettlementSalesExcelList(
  params: Omit<GET_ADMIN_SETTLEMENT_SALES_EXCEL_LIST_REQ, "isExcel">
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementSalesExcelList
    >,
    GET_ADMIN_SETTLEMENT_SALES_EXCEL_LIST_RES
  >({
    queryKey: ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementSalesExcelList({
      ...params,
      isExcel: true,
    }),
    requestOptions: {
      method: "get",
      path: "/settlement/sales/",
      apiType: "ShipdaAdminDefaultNew",
      params: {
        ...params,
        isExcel: true,
      },
    },
    enabled: false,
  });

  const salesExcelList = useMemo(() => {
    return queryResult.data?.list.map((v) => {
      return {
        ...v,
        issueInvoiceType:
          v.issueInvoiceType === "taxation" ? '=""01""' : '=""02""',
        //excel 자체에서 "04"로 입력 시 0을 지우고 4로 변환함 react-csv issues #128에서 찾은 해결법 적용
        receipt: '=""01""',
        depositDateDetail: `=""${v.depositDateDetail}""`,
      };
    });
  }, [queryResult.data?.list]);

  return { ...queryResult, salesExcelList };
}

function useIssueManualInvoice() {
  const mutation = useAppMutation<
    ISSUE_MANUAL_INVOICE_REQ,
    ISSUE_MANUAL_INVOICE_REQ_RES
  >({
    requestOptions: {
      method: "put",
      path: "/settlement/sales/issue",
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E071") {
          return {
            messageType: "titleOnly",
            title: "이미 발행 요청된 거래명세서 입니다.",
          };
        }

        return {
          messageType: "titleOnly",
          title: "발행 요청 중 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...mutation };
}

function useDeleteInvoice({
  id,
  onSuccess,
  onError,
}: {
  id: number;
  onSuccess?: () => void;
  onError?: (error: ResponseFailureInfo | undefined) => void;
}) {
  const mutation = useAppMutation<DELETE_INVOICE_REQ, DELETE_INVOICE_RES>({
    requestOptions: {
      method: "delete",
      path: `/settlement/sales/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess: () => {
      if (onSuccess) onSuccess();
    },

    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return { ...mutation };
}

function useIssueAutoInvoice() {
  const mutation = useAppMutation<
    ISSUE_AUTO_INVOICE_REQ,
    ISSUE_AUTO_INVOICE_RES
  >({
    requestOptions: {
      method: "post",
      path: "/taxInvoice/issue",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useEditInvoiceIssueDate() {
  const mutation = useAppMutation<
    EDIT_INVOICE_ISSUE_DATE_REQ,
    EDIT_INVOICE_ISSUE_DATE_RES
  >({
    requestOptions: {
      method: "put",
      path: "/settlement/sales/issueDate",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useCheckInvoiceCancel(id: number) {
  const mutation = useAppMutation<CHECK_INVOICE_CANCEL_REQ, any>({
    requestOptions: {
      method: "get",
      path: `/taxInvoice/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetSettlementList(params: GET_ADMIN_SETTLEMENT_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementList>,
    GET_ADMIN_SETTLEMENT_LIST_RES
  >({
    keepPreviousData: true,
    queryKey: ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementList(params),
    requestOptions: {
      method: "get",
      path: "/settlement",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetAdminSettlementDepositList(
  params: GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositList
    >,
    GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositList(params),
    requestOptions: {
      method: "get",
      path: "/financial/deposit/list",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useGetAdminSettlementDepositCompanies(
  params: GET_ADMIN_SETTLEMENT_DEPOSIT_COMPANIES_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositCompanies
    >,
    GET_ADMIN_SETTLEMENT_DEPOSIT_COMPANIES_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositCompanies(params),
    requestOptions: {
      method: "get",
      path: "/financial/deposit/companies",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useMatchBidIdToDeposit() {
  const mutation = useAppMutation<
    MATCH_BID_ID_TO_DEPOSIT_REQ,
    MATCH_BID_ID_TO_DEPOSIT_RES
  >({
    requestOptions: {
      method: "put",
      path: "/financial/deposit/matching",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useRegisterFinancialMemo() {
  const mutation = useAppMutation<
    REGISTER_FINANCIAL_MEMO_REQ,
    REGISTER_FINANCIAL_MEMO_RES
  >({
    requestOptions: {
      method: "post",
      path: "/financial/memo",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetAdminSettlementDepositExcelList({
  enabled,
  ...params
}: GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_EXCEL_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositExcelList
    >,
    GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_EXCEL_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositExcelList(params),
    requestOptions: {
      method: "get",
      path: "/financial/deposit/list",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetAdminSettlementWithdrawalList(
  params: GET_ADMIN_SETTLEMENT_WITHDRAWAL_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementWithdrawalList
    >,
    GET_ADMIN_SETTLEMENT_WITHDRAWAL_LIST_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementWithdrawalList(params),
    requestOptions: {
      method: "get",
      path: "/financial/withdraw/list",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useGetAdminSettlementWithdrawalPartners(
  params: GET_ADMIN_SETTLEMENT_WITHDRAWAL_PARTNERS_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementWithdrawalPartners
    >,
    GET_ADMIN_SETTLEMENT_WITHDRAWAL_PARTNERS_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementWithdrawalPartners(
        params
      ),
    requestOptions: {
      method: "get",
      path: "/financial/withdraw/partners",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useMatchBidIdToWithdrawal() {
  const mutation = useAppMutation<
    MATCH_BID_ID_TO_WITHDRAWAL_REQ,
    MATCH_BID_ID_TO_WITHDRAWAL_RES
  >({
    requestOptions: {
      method: "put",
      path: "/financial/withdraw/matching",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetAdminSettlementWithdrawalExcelList({
  enabled,
  ...params
}: GET_ADMIN_SETTLEMENT_WITHDRAWAL_EXCEL_LIST_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementWithdrawalExcelList
    >,
    GET_ADMIN_SETTLEMENT_WITHDRAWAL_EXCEL_LIST_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementWithdrawalExcelList(
        params
      ),
    requestOptions: {
      method: "get",
      path: "/financial/Withdraw/list",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useForceCancelTaxInvoice(invoiceId: number) {
  const mutation = useAppMutation<
    FORCE_CANCEL_TAX_INVOICE_REQ,
    FORCE_CANCEL_TAX_INVOICE_RES
  >({
    requestOptions: {
      method: "post",
      path: `/taxInvoice/cancel/${invoiceId}/force`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetAdminSettlementSalesChitExcelList(
  params: Omit<GET_ADMIN_SETTLEMENT_SALES_CHIT_EXCEL_LIST_REQ, "isExcel">
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementSalesChitExcelList
    >,
    SettlementSalesChitExcelListData[]
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementSalesChitExcelList({
        ...params,
        isExcel: true,
      }),
    requestOptions: {
      method: "get",
      path: "/settlement/sales/slip",
      apiType: "ShipdaAdminDefaultNew",
      params: {
        ...params,
        isExcel: true,
      },
    },
    enabled: false,
  });

  return { ...queryResult };
}

function useGetAdminSettlementPurchaseList(
  params: GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementPurchaseList
    >,
    GET_ADMIN_SETTLEMENT_PURCHASE_LIST_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementPurchaseList(params),
    requestOptions: {
      method: "get",
      path: "/settlement/purchaseManagement",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useGetAdminSettlementPurchaseExcelList(
  params: GET_ADMIN_SETTLEMENT_PURCHASE_EXCEL_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementPurchaseExcelList
    >,
    GET_ADMIN_SETTLEMENT_PURCHASE_EXCEL_LIST_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementPurchaseExcelList(
        params
      ),
    requestOptions: {
      method: "get",
      path: "/settlement/purchaseManagement",
      apiType: "ShipdaAdminDefaultNew",
      params: {
        ...params,
        isExcel: true,
      },
    },

    enabled: false,
  });

  // TODO:임시로 react-query에서 해결 정산 작업 시 테이블 컴포넌트로 수정 예정
  const excelList = useMemo(() => {
    return queryResult.data?.list.map((v) => {
      return {
        bidId: v.bidId,
        mbl: v.bid.management.mBL,
        hbl: v.bid.management.hBL,
        userName: v.bid.user.name,
        partnerName: v.company.name,
        bidCreatedAt: toFormattedDate(v.bid.createdAt),
        companyType: changePurchaseDomainToKr(v.companyType),
        BRN: v.BRN,
        currency: v.currency,
        foreignFinalPrice: v.foreignFinalPrice,
        exchangeRate: v.exchangeRate,
        finalPrice: v.finalPrice,
        forwardingManager: v.forwardingManager.name,
        ETD: v.bid.management.ETD,
        ETA: v.bid.management.ETA,
        createdAt: toFormattedDate(v.createdAt),
        withdrawalInvoiceId: v.withdrawalInvoiceId,
        withdrawalInvoiceDate: toFormattedDate(
          v.withdrawalInvoiceDate,
          "YYYY-MM-DD"
        ),
        isWithdrawn: v.isWithdrawn ? "예" : "아니오",
        withdrawalDate: v.withdrawalDate,
        lastUpdate:
          v.transactionLogs && v.transactionLogs.length > 0
            ? v.transactionLogs[0].createdAt
            : "-",
      };
    });
  }, [queryResult.data?.list]);

  return { ...queryResult, excelList };
}

function useCancelWithdraw() {
  const mutation = useAppMutation<CANCEL_WITHDRAW_REQ, CANCEL_WITHDRAW_RES>({
    requestOptions: {
      method: "put",
      path: "/settlement/purchaseManagement",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useEditWithdrawDate() {
  const mutation = useAppMutation<
    EDIT_WITHDRAWAL_DATE_REQ,
    EDIT_WITHDRAWAL_DATE_RES
  >({
    requestOptions: {
      method: "put",
      path: "/settlement/purchaseManagement/withdrawalDate",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useDeleteWithdraw() {
  const mutation = useAppMutation<DELETE_WITHDRAW_REQ, DELETE_WITHDRAW_RES>({
    requestOptions: {
      method: "put",
      path: "/settlement/purchaseManagement",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useCheckRemittanceCertificate(bidId: number) {
  const mutation = useAppMutation<
    CHECK_REMITTANCE_CERTIFICATE_REQ,
    CHECK_REMITTANCE_CERTIFICATE_RES
  >({
    requestOptions: {
      method: "put",
      path: `/settlement/purchaseManagement/${bidId}/remittanceCertificate`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetAdminSettlementDepositV2List(
  params: GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_V2_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositV2List
    >,
    GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_V2_RES
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositV2List(params),
    requestOptions: {
      method: "get",
      path: "/financial/deposit/v2/list",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useGetAdminSettlementDepositV2ExcelList({
  enabled,
}: {
  enabled: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositV2ExcelList
    >,
    BlobPart
  >({
    queryKey:
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementDepositV2ExcelList(),
    requestOptions: {
      method: "get",
      path: "/financial/deposit/excel",
      apiType: "ShipdaAdminDefaultNew",
      responseType: "blob",
    },
    enabled,

    onSuccess: (data) => {
      downloadFile({
        fileName: "입금관리 엑셀리스트.xlsx",
        fileContent: data,
      });
    },
  });

  return { ...queryResult };
}

const ADMIN_SETTLEMENT_QUERY = {
  useGetAdminSettlementSalesList,
  useGetAdminSettlementSalesExcelList,
  useIssueManualInvoice,
  useDeleteInvoice,
  useIssueAutoInvoice,
  useEditInvoiceIssueDate,
  useCheckInvoiceCancel,
  useGetSettlementList,
  useGetAdminSettlementDepositList,
  useGetAdminSettlementDepositCompanies,
  useMatchBidIdToDeposit,
  useRegisterFinancialMemo,
  useGetAdminSettlementDepositExcelList,
  useGetAdminSettlementWithdrawalList,
  useGetAdminSettlementWithdrawalPartners,
  useMatchBidIdToWithdrawal,
  useGetAdminSettlementWithdrawalExcelList,
  useForceCancelTaxInvoice,
  useGetAdminSettlementSalesChitExcelList,
  useGetAdminSettlementPurchaseList,
  useGetAdminSettlementPurchaseExcelList,
  useCancelWithdraw,
  useEditWithdrawDate,
  useDeleteWithdraw,
  useCheckRemittanceCertificate,
  useGetAdminSettlementDepositV2List,
  useGetAdminSettlementDepositV2ExcelList,
};

export default ADMIN_SETTLEMENT_QUERY;
